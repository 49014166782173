/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const Cookies = require('react-cookie').Cookies;

const oneYear = 365 * 24 * 60 * 60;

exports.onRouteUpdate = ({ location, prevLocation }) => {
  /*const cookies = new Cookies();
  if (prevLocation && location.pathname !== '/cookie-policy') {
    cookies.set('show-cookie-bar', false, { maxAge:oneYear});
  }*/
};
